export function groupItemBy(array: any[], property: string) {
    let hash: any = {}
    let props = property.split('.')
    for (var i = 0; i < array.length; i++) {
        let key = props.reduce(function(acc, prop) {
            return acc && acc[prop]
        }, array[i])
        if (!hash[key]) hash[key] = []
        hash[key].push(array[i])
    }
    return hash
}