import React, { useEffect, useState } from 'react'

import Avatar from '@material-ui/core/Avatar'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { IBus } from '../api/stops.api'
import formatTime from '../custom-functions/formatTime'
import { BusIcon, TaxiIcon, TramIcon, TrolleyIcon } from '../icons/TransportIcons'
import { groupItemBy } from '../custom-functions/groupItemBy'
import { sortByWait } from '../custom-functions/sortByWait'

interface IVTList {
    vt?: IBus[]
    label: string
}
export interface ISortedByName {
    [key: string]: IBus[]
}

export const VTList: React.FC<IVTList> = ({ vt, label }) => {

    const [expandList, setExpandList] = useState(true)
    const [sortedByWait, setSortedByWait] = useState<any>(null)

    useEffect(() => {
        if (vt) {
            const toSortByWait: ISortedByName = groupItemBy(vt, 'route.name')
            setSortedByWait(sortByWait(toSortByWait))
        }
    }, [vt])

    return <div>
        <ListItem button onClick={!vt ? () => { } : () => { setExpandList(!expandList) }}>
            <ListItemText>
                {!vt ? `${label} (маршрутов нет)` : label}
            </ListItemText>
            {vt && <div>
                {expandList ? <ExpandLess /> : <ExpandMore />}
            </div>}
        </ListItem>
        <Collapse in={!vt ? false : expandList}>
            <List>
                <div>
                    {sortedByWait?.map((route: { [key: string]: IBus[] }, i: any) => {
                            return Object.entries(route).map((val, i) => {
                                const waitTime = formatTime(val[1][0].wait.split('.')[0])
                                const arriveTime1 = new Date(val[1][0].time).toLocaleTimeString('ru-RU', {
                                    hour: "numeric",
                                    minute: "numeric"
                                })
                                let arriveTime2 = ''
                                if (val[1][1]) {
                                    arriveTime2 = new Date(val[1][1].time).toLocaleTimeString('ru-RU', {
                                        hour: "numeric",
                                        minute: "numeric"
                                    })
                                }
                                return <div key={i}>
                                    <ListItem style={{ minHeight: '69px', paddingRight: '100px' }}>
                                        <ListItemAvatar style={{ minWidth: '32px' }}>
                                            <Avatar alt="Bus" style={{
                                                marginRight: '12px',
                                                width: '32px',
                                                height: '32px',
                                                backgroundColor: val[1][0].route.vt === 'tr' ? '#FF5A4B'
                                                    : val[1][0].route.vt === 'rt' ? '#15B141'
                                                        : val[1][0].route.vt === 'b' ? '#4478FF'
                                                            : val[1][0].route.vt === 'tb' ? '#CA33FF'
                                                                : ''
                                            }}>
                                                {val[1][0].route.vt === 'tr' ? <TramIcon />
                                                    : val[1][0].route.vt === 'rt' ? <TaxiIcon />
                                                        : val[1][0].route.vt === 'b' ? <BusIcon />
                                                            : val[1][0].route.vt === 'tb' ? <TrolleyIcon />
                                                                : '??'}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            <Typography style={{ fontWeight: 'bold' }}>
                                                {val[0]}
                                            </Typography>
                                            <Typography style={{ color: '#545454' }} variant="body2">
                                                {!!val[1][0].direction.endName && `до ${val[1][0].direction.endName}`}
                                            </Typography>
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <Typography align="right" style={{ fontWeight: 'bold', color: waitTime === 'прибывает' ? '#1B9D06' : '' }}>
                                                {waitTime}
                                            </Typography>
                                            <Typography variant="body2" align="right" style={{ color: '#545454' }}>
                                                {arriveTime1}
                                                {arriveTime2 !== '' ? `, ${arriveTime2}` : ''}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {i !== Object.entries(route).length - 1 && <Divider component="li" style={{ backgroundColor: '#fafafa' }} />}
                                </div>
                            })
                        })
                    }
                </div>
            </List>
        </Collapse>
    </div>
};
