const formatTime = (waitTime: string) => {
    let waitTimeNormalized = ''
    if (waitTime.split(':')[0] > '00') {
        waitTimeNormalized = Number(waitTime.split(':')[0]) + ':' + waitTime.split(':')[1]
    }
    if (waitTime.split(':')[0] === '00') {
        if (waitTime.split(':')[1] === '00') {
            waitTimeNormalized = 'прибывает'
        }
        if (waitTime.split(':')[1] > '00') {
            if (waitTime.split(':')[1].substr(0, 1) === '0') {
                waitTimeNormalized = waitTime.split(':')[1].substr(1, 1) + ' мин'
            }
            if (waitTime.split(':')[1].substr(0, 1) > '0') {
                waitTimeNormalized = waitTime.split(':')[1] + ' мин'
            }
        }
    }
    return waitTimeNormalized
}

export default formatTime