import { ISortedByName } from '../components/VTList'

export const sortByWait = (vt: ISortedByName) => {
    const vtToSortArray = Object
        .keys(vt)
        .map(route => ({
            [route]: vt[route]
        }));
    return vtToSortArray.sort((a, b) => {
        const aVal = Number(Object.values(a)[0][0].wait.replace(':', '').replace(':', '').slice(0, 7))
        const bVal = Number(Object.values(b)[0][0].wait.replace(':', '').replace(':', '').slice(0, 7))
        return aVal - bVal;
    });
};
