import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import CssBaseline from '@material-ui/core/CssBaseline'

import QRGenerator from './pages/QRGenerator'
import StopsList from './pages/StopsList'

const App: React.FC = () => {
    return <>
        <CssBaseline />
        <Switch>
            <Route path='/stop' render={() => <StopsList />} />
            <Route path='/qr' render={() => <QRGenerator />} />
            <Redirect to='/stop' />
        </Switch>
    </>
}

export default App