import { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { Page, Text, Document, PDFViewer, Image, StyleSheet, Font } from '@react-pdf/renderer'

import AppBar from '@material-ui/core/AppBar'
import TextField from '@material-ui/core/TextField'
import Toolbar from "@material-ui/core/Toolbar"
import Typography from '@material-ui/core/Typography'
import { DataGrid, GridColDef, GridRowId } from "@material-ui/data-grid"

import QRGen from "../custom-functions/QRGen"
import getCurrentStopFromStops from "../custom-functions/getCurrentStop"
import { IStop, stopsAPI } from "../api/stops.api"

Font.register({
    family: "Roboto",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

const styles = StyleSheet.create({
    myText: {
        fontFamily: "Roboto",
        marginTop: 16,
        textAlign: 'center'
    }
})

const PDFQR: React.FC<{ stops: GridRowId[], srv: string, allStops: IStop[] }> = ({ stops, srv, allStops }) => {
    return <PDFViewer width='100%' height='100%'>
        <Document>
            {stops.map((stop, index) => {
                const currentStop = getCurrentStopFromStops(allStops, stop)[0].name
                const qr: string = QRGen(`${document.location.origin}/stop?id=${stop}&srv=${srv}&lang=ru`)
                return <Page size="A3" key={index}>
                    <Text style={styles.myText}>{`ID остановки: ${stop}`}</Text>
                    <Text style={styles.myText}>{`Наименование: ${currentStop}`}</Text>
                    <Image src={qr} />
                </Page>
            })}
        </Document>
    </PDFViewer>
}

const QRGenerator: React.FC = () => {

    const [srvFind, setSrvFind] = useState('')
    const [stops, setStops] = useState<IStop[] | null>(null)
    const [selectedStops, setSelectedStops] = useState<GridRowId[]>([])

    const { handleSubmit, control } = useForm()

    const searchServer = (data: { server: string }) => {
        setSrvFind(data.server)
    }

    useEffect(() => {
        if (!!srvFind) {
            stopsAPI.getStops(srvFind)
                .then(stops => {
                    setStops(stops)
                })
        }
    }, [srvFind])

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 300
        },
        {
            field: 'name',
            headerName: 'Название',
            width: 500
        }
    ];

    return <div style={{ height: '100vh' }}>
        <AppBar position="sticky">
            <Toolbar>
                <Typography variant="h6">
                    QR-генератор
                </Typography>
            </Toolbar>
        </AppBar>
        <div style={{ padding: '16px', width: '100%', height: 'calc(100% - 112px)'}}>
            <div style={{ marginBottom: '8px' }}>
                <form onSubmit={handleSubmit(searchServer)}>
                    <Controller
                        name="server"
                        control={control}
                        defaultValue=''
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                label="Сервер"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                size="small"
                                required
                                fullWidth={true}
                            />
                        )}
                    />
                </form>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '100%', height: '100%' }}>
                {stops && <div style={{ display: 'flex', height: '100%', flexGrow: 1, marginRight: selectedStops.length !== 0 ? '8px' : '' }}>
                    <DataGrid
                        rows={stops}
                        columns={columns}
                        checkboxSelection
                        disableSelectionOnClick
                        onSelectionModelChange={stop => setSelectedStops(stop)}
                    />
                </div>}
                {selectedStops.length !== 0 && <div style={{ display: 'flex', height: '100%', flexGrow: 2 }}>{!!stops && <PDFQR stops={selectedStops} srv={srvFind} allStops={stops} />}</div>}
            </div>
        </div>
    </div>
}

export default QRGenerator