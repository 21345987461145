import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'

import { IBus, IStop, stopsAPI } from '../api/stops.api'
import getCurrentStopFromStops from '../custom-functions/getCurrentStop'
import { groupItemBy } from '../custom-functions/groupItemBy'

import { VTList } from '../components/VTList'
//import GoogleAds from '../components/GoogleAds'

const StopsList: React.FC = () => {

    const history = useHistory()
    const urlParams = new URLSearchParams(history.location.search)
    const currentStop = urlParams.get('id')
    const currentSrv = urlParams.get('srv')
    const currentLang = urlParams.get('lang')

    const [stops, setStops] = useState<IStop[] | null>(null)

    interface INewStop {
        tr?: IBus[]
        b?: IBus[]
        tb?: IBus[]
        rt?: IBus[]
    }
    const [stop, setStop] = useState<INewStop | null>(null)

    
    useEffect(() => {
        if (!!currentSrv) {
            stopsAPI.getStops(currentSrv)
                .then(stops => {
                    setStops(stops)
                })
        }
    }, [currentSrv])

    useEffect(() => {
        if (!!currentStop && !!currentSrv && !!currentLang) {
            stopsAPI.getStopInfo({ zoneId: currentStop, srv: currentSrv, lang: currentLang })
                .then(stop => {
                    setStop(groupItemBy(stop, 'route.vt'))
                })
        }

    }, [currentStop, currentSrv, currentLang])
    useEffect(() => {
        if (!!currentStop && !!currentSrv && !!currentLang) {
            setInterval(() => {
                stopsAPI.getStopInfo({ zoneId: currentStop, srv: currentSrv, lang: currentLang })
                    .then(stop => {
                        setStop(groupItemBy(stop, 'route.vt'))
                    })
            }, 15000)
        }
        return clearInterval()
    }, [currentStop, currentSrv, currentLang])

    const [stopName, setStopName] = useState('')

    useEffect(() => {
        if (stops) {
            setStopName(getCurrentStopFromStops(stops, currentStop)[0].name)
        }
    }, [stops, currentStop])

    if (!stop) return <></>
    return <>
        <div style={{
            padding: '16px',
            borderBottom: '1px solid #fafafa',
            backgroundColor: 'white',
            position: 'sticky',
            top: 0,
            left: 'auto',
            right: 0,
            zIndex: 1000,
        }}>
            <Typography style={{
                fontSize: '20px',
                fontWeight: 'bold',
                lineHeight: 1,
                marginBottom: '8px'
            }}>
                {stopName}
            </Typography>
            <Typography variant="body2" style={{
                color: '#545454'
            }}>
                {'Остановка общественного транспорта'}
            </Typography>
        </div>
        <List style={{ backgroundColor: 'white', margin: '12px 0' }}>
            <Typography style={{
                fontWeight: 'bold',
                padding: '8px 16px 16px 16px'
            }}>
                Прогноз прибытия
            </Typography>
            <VTList vt={stop?.rt} label={'Маршрутные такси'} />
            <Divider component="li" style={{ backgroundColor: '#fafafa' }} />
            <VTList vt={stop?.b} label={'Автобусы'} />
            <Divider component="li" style={{ backgroundColor: '#fafafa' }} />
            <VTList vt={stop?.tb} label={'Троллейбусы'} />
            <Divider component="li" style={{ backgroundColor: '#fafafa' }} />
            <VTList vt={stop?.tr} label={'Трамваи'} />
        </List>
        {/* <div style={{
            padding: '16px',
            backgroundColor: 'white'
        }}>
            <Typography style={{
                fontSize: '20px',
                fontWeight: 'bold',
                lineHeight: 1,
                marginBottom: '8px'
            }}>
                Реклама
                <GoogleAds />
            </Typography>
        </div> */}
    </>
}

export default StopsList