import api from "./api"

interface IStopQueryParams {
    zoneId: string
    srv: string
    lang: string
}

export interface IStop {
    id: string
    name: string
}

export interface IBus {
    direction: {
        endName: string
        letter: string
    }
    object: {
        garageNum: string
        id: string
        model: string
        stateNum: string
    }
    route: {
        name: string
        vt: string
    }
    time: string
    wait: string
}

export const stopsAPI = {
    getStopInfo(stopQueryParams: IStopQueryParams) {
        return api.get<IBus[]>(`/api/getstop?zoneId=${stopQueryParams.zoneId}&srv=${stopQueryParams.srv}&lang=${stopQueryParams.lang}`)
        .then(res => res.data)
    },
    getStops(srv: string) {
        return api.get<IStop[]>(`/api/getstops?srv=${srv}`)
        .then(res => res.data)
    }
}