import SvgIcon from "@material-ui/core/SvgIcon"

const styles = {
    svgStyled: {
        width: '20px',
        height: '20px'
    }
}

function BusIcon(props: any) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 20" style={styles.svgStyled}>
            <path d="M3 13.7896C3 14.5306 3.32842 15.1959 3.84211 15.659V16.7369C3.84211 17.4359 4.40632 18.0001 5.10526 18.0001C5.80421 18.0001 6.36842 17.4359 6.36842 16.7369V16.3159H13.1053V16.7369C13.1053 17.4275 13.6695 18.0001 14.3684 18.0001C15.0589 18.0001 15.6316 17.4359 15.6316 16.7369V15.659C16.1453 15.1959 16.4737 14.5306 16.4737 13.7896V5.36851C16.4737 2.42114 13.4589 2.00009 9.73684 2.00009C6.01474 2.00009 3 2.42114 3 5.36851V13.7896ZM5.94737 14.6317C5.24842 14.6317 4.68421 14.0675 4.68421 13.3685C4.68421 12.6696 5.24842 12.1054 5.94737 12.1054C6.64632 12.1054 7.21053 12.6696 7.21053 13.3685C7.21053 14.0675 6.64632 14.6317 5.94737 14.6317ZM13.5263 14.6317C12.8274 14.6317 12.2632 14.0675 12.2632 13.3685C12.2632 12.6696 12.8274 12.1054 13.5263 12.1054C14.2253 12.1054 14.7895 12.6696 14.7895 13.3685C14.7895 14.0675 14.2253 14.6317 13.5263 14.6317ZM14.7895 9.57904H4.68421V5.36851H14.7895V9.57904Z" />
        </SvgIcon>
    )
}
function TramIcon(props: any) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 20" style={styles.svgStyled}>
            <path d="M10.5641 4.05128L11.1795 2.82051H14.2564C14.483 2.82051 14.6667 2.63683 14.6667 2.41026C14.6667 2.18368 14.483 2 14.2564 2H5.23077C5.00419 2 4.82051 2.18368 4.82051 2.41026C4.82051 2.63683 5.00419 2.82051 5.23077 2.82051H9.53846L8.92308 4.05128C6.35487 4.12513 4 4.65026 4 6.92308V13.8974C4 15.1282 4.91077 16.1374 6.09231 16.3179L5.1159 17.2944C4.85333 17.5569 5.03385 18 5.40308 18H6.29744C6.4041 18 6.51077 17.959 6.58461 17.8769L8.10256 16.359H11.3846L12.9026 17.8769C12.9764 17.9508 13.0831 18 13.1897 18H14.0841C14.4533 18 14.6338 17.5569 14.3713 17.3026L13.3949 16.3262C14.5764 16.1374 15.4872 15.1282 15.4872 13.8974V6.92308C15.4872 4.65026 13.1323 4.12513 10.5641 4.05128ZM9.74359 15.1282C9.06256 15.1282 8.51282 14.5785 8.51282 13.8974C8.51282 13.2164 9.06256 12.6667 9.74359 12.6667C10.4246 12.6667 10.9744 13.2164 10.9744 13.8974C10.9744 14.5785 10.4246 15.1282 9.74359 15.1282ZM13.8462 11.4359H5.64103V7.33333H13.8462V11.4359Z" />
        </SvgIcon>
    )
}
function TaxiIcon(props: any) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 20" style={styles.svgStyled}>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.21471 2.00006C6.74916 2.00006 6.37176 2.37746 6.37176 2.843V3.68594C6.37176 3.68849 6.37178 3.69104 6.3718 3.69359C4.35637 3.79441 3 4.27201 3 6.24619V13.8014C3 14.5404 3.3275 15.2038 3.83973 15.6656V16.7405C3.83973 17.4374 4.40235 18.0001 5.09933 18.0001C5.79631 18.0001 6.35893 17.4374 6.35893 16.7405V16.3206H13.0768V16.7405C13.0768 17.429 13.6394 18.0001 14.3364 18.0001C15.025 18.0001 15.596 17.4374 15.596 16.7405V15.6656C16.1082 15.2038 16.4357 14.5404 16.4357 13.8014V6.24619C16.4357 4.28882 15.1023 3.80265 13.1152 3.69625C13.1153 3.69282 13.1153 3.68938 13.1153 3.68594V2.843C13.1153 2.37746 12.7379 2.00006 12.2724 2.00006H7.21471ZM4.67946 13.3815C4.67946 14.0785 5.24208 14.6411 5.93906 14.6411C6.63604 14.6411 7.19866 14.0785 7.19866 13.3815C7.19866 12.6846 6.63604 12.1219 5.93906 12.1219C5.24208 12.1219 4.67946 12.6846 4.67946 13.3815ZM12.2371 13.3815C12.2371 14.0785 12.7997 14.6411 13.4966 14.6411C14.1936 14.6411 14.7562 14.0785 14.7562 13.3815C14.7562 12.6846 14.1936 12.1219 13.4966 12.1219C12.7997 12.1219 12.2371 12.6846 12.2371 13.3815ZM5.67946 5.20006C5.12718 5.20006 4.67946 5.64778 4.67946 6.20006V10.0001H14.7562V6.20006C14.7562 5.64778 14.3085 5.20006 13.7562 5.20006H5.67946Z" />
        </SvgIcon>
    )
}
function TrolleyIcon(props: any) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 20" style={styles.svgStyled}>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.2094 2.79817C14.3942 2.59313 14.3661 2.28693 14.1466 2.11426C13.9271 1.9416 13.5993 1.96785 13.4144 2.1729L11.8385 3.92104C11.2575 3.90344 10.6387 3.90344 9.99923 3.90344C9.37203 3.90344 8.76954 3.90344 8.20445 3.92005L6.62941 2.1729C6.44456 1.96785 6.11676 1.9416 5.89725 2.11426C5.67774 2.28693 5.64964 2.59313 5.83448 2.79818L6.91895 4.00114C5.19086 4.18948 4.0395 4.74087 4 6.35517V14.4976C4 15.114 4.29248 15.6674 4.74994 16.0527V16.9494C4.74994 17.5308 5.2524 18.0002 5.87485 18.0002C6.4973 18.0002 6.99976 17.5308 6.99976 16.9494V16.5991H12.9993V16.9494C12.9993 17.5238 13.5017 18.0002 14.1242 18.0002C14.7391 18.0002 15.2491 17.5308 15.2491 16.9494V16.0527C15.7066 15.6674 15.999 15.114 15.999 14.4976V6.35517C16.0384 4.74567 14.8691 4.19277 13.1234 4.00283L14.2094 2.79817ZM6.62479 15.1981C6.00234 15.1981 5.49988 14.7287 5.49988 14.1473C5.49988 13.5659 6.00234 13.0965 6.62479 13.0965C7.24724 13.0965 7.7497 13.5659 7.7497 14.1473C7.7497 14.7287 7.24724 15.1981 6.62479 15.1981ZM13.3742 15.1981C12.7518 15.1981 12.2493 14.7287 12.2493 14.1473C12.2493 13.5659 12.7518 13.0965 13.3742 13.0965C13.9967 13.0965 14.4992 13.5659 14.4992 14.1473C14.4992 14.7287 13.9967 15.1981 13.3742 15.1981ZM14.4992 11.5058C14.4992 11.5058 8.12467 11.5058 5.49988 11.5058V7.40602H14.4992V11.5058ZM7.56221 5.30423C7.25158 5.30423 6.99976 5.53946 6.99976 5.82962C6.99976 6.11978 7.25158 6.35501 7.56221 6.35501H12.4368C12.7475 6.35501 12.9993 6.11978 12.9993 5.82962C12.9993 5.53946 12.7475 5.30423 12.4368 5.30423H7.56221Z" />
        </SvgIcon>
    )
}

export { BusIcon, TramIcon, TaxiIcon, TrolleyIcon }